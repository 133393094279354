<template>
  <div>
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="Kullanıcı Adı"
                label-for="username"
            >
              <b-form-input
                  id="username"
                  placeholder="Kullanıcı Adı"
                  v-model="username"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="Şifre"
                label-for="pass"
            >
              <b-form-input
                  id="pass"
                  placeholder="Şifre"
                  v-model="password"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="Rol"
                label-for="role"
            >
              <b-form-radio-group
                  id="role"
                  v-model="selected"
                  :options="options"
                  class="demo-inline-spacing mb-1"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.once="userSave"
            >
              Ekle
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormRadioGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BBadge,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BFormRadioGroup,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BBadge,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: false,
      options: [
        { value: true, text: 'Admin' },
        { value: false, text: 'Kullanıcı' },
      ],
      username: '',
      password: '',
    }
  },
  computed: {},
  mounted() {

  },
  created() {

  },
  methods: {
    userSave() {
      axios.post('/user/add', {
        username: this.username,
        isAdmin: this.selected,
        password: this.password,
      }).then(response => {
        this.$toastApiResponse(response.data)
        this.$emit('createModal', true)
      })
          .catch(err => {
            this.$toastBaha(err.message, 'danger')
          })
    }
  },
}
</script>

<style>

</style>
